import React from "react";

function PrivacyPolicy() {
  return (
    <div className=" max-w-[1080px] px-5 py-3 text-sm mx-auto ">
      <h1 className="text-2xl pb-3 text-red-500 text-center">Privacy Policy</h1>
      <p className="pb-3">Date of Last Revision: January 15, 2024</p>
      <p className="pb-3">
        Welcome to the website (the “Site”) of HOMEALS INDIA PVT LTD, Inc.
        HOMEALS INC USA, Inc. (“HAPPYOMEALS,” “we,” “us,” or “our”). HAPPYOMEALS
        provides an online marketplace in which Sellers (defined below) can
        list, offer, sell and deliver food items and meal orders to the general
        public, and customers can browse and purchase Meals (collectively,
        including the Site and any related mobile applications, the “Service”).
      </p>
      <p className="pb-3">
        This Privacy Policy explains what information we collect, how we use and
        share that data, and your choices concerning our data practices.
      </p>
      <p className="pb-3">
        Before using the Service or submitting any information to HAPPYOMEALS,
        please review this Privacy Policy carefully and contact us at
        privacy@HAPPYOMEALS.com if you have any questions. By using the Service,
        you agree to our Terms of Service and the practices described in this
        Privacy Policy. If you do not agree to this Privacy Policy and our Terms
        of Service, please do not access the Site or otherwise use the Service.
      </p>
      <p className="pb-3">1. INFORMATION WE COLLECT</p>
      <p className="pb-3">We collect information as follows:</p>
      <p className="pb-3">
        Information You Provide: We collect information that you provide to
        HAPPYOMEALS when (i) filling out or updating required and optional
        profile information when signing up for and using the Service, (ii)
        contacting us by email, (iii) filling out a contact form on the Service;
        (iv) making a purchase; (v) participating in a promotion; or (vi)
        applying for a job. The information collected during these interactions
        may vary based on what you choose to share with us, but it will
        generally include name, e-mail address, telephone number, account
        credentials, demographic data (such as gender, race, and country), your
        delivery address, other profile data (such as your preferences and
        favorites), employment data (such as your employment and education
        history, transcripts, and references as necessary to consider you for
        open positions), the content of any message you send to us or reviews
        and feedback you submit about Sellers’ Meals that may be publicly posted
        on the Service at Seller’s discretion, and the financial information
        necessary to ensure payments can be processed by our payment processor
        Stripe, Inc. (“Stripe”). Accordingly, in addition to this Privacy Policy
        and our Terms of Service, information related to your purchases is also
        processed according to Stripe's services agreement and privacy policy.
        We collect the content within any messages exchanged between users and
        Sellers through the Service (such as if you use our chat functionality).
        We also collect data about your personal contacts, including the first
        and last name, email address, and phone number of your personal
        contacts. We collect data about your contacts with your consent and in
        order to fulfill a request by you, such as inviting your contacts to
        join the Service. Such functionality is only intended for U.S.
        residents. By using this functionality, you acknowledge and agree that
        both you and your contacts are based in the U.S. and that you have your
        contacts’ consent for us to use their contact information to fulfill
        your request.
      </p>
      <p className="pb-3">
        Information We Collect Through Our Social Media Pages and Chatbots: We
        have pages on social media sites like Instagram, Twitter, LinkedIn, and
        Facebook (“Social Media Pages”). When you interact with our Social Media
        Pages (e.g., through our chatbot on Facebook Messenger), we will collect
        the information that you provide to us, such as your contact details.
        Also, if you publicly reference our Service on social media (e.g., by
        using a hashtag associated with HAPPYOMEALS in a tweet or post), we may
        use your reference on or in connection with our Service. In addition,
        the companies that host our Social Media Pages may provide us with
        aggregate information and analytics regarding the use of our Social
        Media Pages.
      </p>
      <p className="pb-3">
        Information We Collect When You Log-in Through Social Media: We may
        embed a pixel or SDK on our Service that allows you to log-in to your
        HAPPYOMEALS account through social media. If you choose to engage with
        such integration, we may receive information from the social media
        platform that you have authorized to share with us. Please note that the
        social media platform may independently collect information about you
        through the integration.
      </p>
      <p className="pb-3">
        Information We Receive Automatically From Your Use of the Service: When
        you visit, use and interact with the Service, we may receive certain
        information about your visit, use or interactions. For example, we may
        monitor the number of people that visit the Service, peak hours of
        visits, which page(s) you visit, which links you click, what text you
        enter, how your mouse moves around the Service, the domains you come
        from (e.g., google.com, facebook.com, etc.), and which browsers you use
        to access the Service (e.g., Google Chrome, Firefox, Microsoft Internet
        Explorer, etc.), broad geographical information (such as location
        derived from an IP address or data that indicates a city or postal code
        level) and precise location data (such as latitude/longitude data when
        you enter you address for a Meal delivery), and navigation patterns. In
        particular, the following information is created and automatically
        logged in our systems:
      </p>
      <p>
        • Log data: Information that your browser automatically sends whenever
        you visit the Site (“log data”). Log data includes your Internet
        Protocol address, browser type and settings, the date and time of your
        request, and how you interacted with the Site.
      </p>
      <p>
        • Device information: Includes name of the device, operating system,
        internet service provider, your device’s regional and language settings,
        device identifiers (e.g., IP address), and browser you are using.
        Information collected may depend on the type of device you use and its
        settings.
      </p>
      <p className="pb-3">
        • Usage Information: We collect information about how you use our
        Service, such as the types of content that you view or engage with, the
        features you use, the actions you take (including the Meals you view and
        purchase), the emails you view, and the time, frequency and duration of
        your activities.
      </p>
      <p className="pb-3">
        Information We Receive from Other Sources: We collect information from
        (i) data brokers, from which we purchase data to supplement the data we
        collect; (ii) partners, who offer co-branded services, sell or
        distribute our products and services, or engage in joint marketing
        activities; and (iii) publicly-available sources, including data in the
        public domain.
      </p>
      <p className="pb-3">
        Information We Collect if You use the Service as a Seller: If you sign
        up to be a Seller on the Service, we collect information about you such
        as your first and last name, email address, telephone number, postal
        address, health code enforcement agency permit number (where
        applicable), government issued I.D. (such as a driver’s license),
        demographic data, account credentials, financial information, and other
        information collected by our service provider(s) who assist with
        providing the Service for Sellers. We use this information to create and
        administer your HAPPYOMEALS account and facilitate communications
        between you and individuals who purchase Meals from you through the
        Service.
      </p>
      <p className="pb-3">
        Cookies: We use cookies to operate and administer our Site, gather Usage
        Information on our Site, support security features, bring you
        advertising off the Service, and improve your experience on it. A
        “cookie” is a piece of information sent to your browser by a website you
        visit. Cookies can be stored on your computer for different periods of
        time. Some cookies expire after a certain amount of time, or upon
        logging out (session cookies), others survive after your browser is
        closed until a defined expiration date set in the cookie (as determined
        by the party placing it), and help recognize your computer when you open
        your browser and browse the Internet again (persistent cookies). For
        more details on cookies please visit www.allaboutcookies.org.
      </p>
      <p className="pb-3">
        Pixels: We use pixels (also known as web beacons), which is code
        embedded in a website, video, email, or advertisement, to send
        information about your use of the Service to a server. There are various
        types of pixels, including image pixels (which are small graphic images)
        and JavaScript pixels (which contains JavaScript code). When you access
        a website, video, email, or advertisement that contains a pixel, the
        pixel may permit us or a separate entity to drop or read cookies on your
        browser. Pixels are used in combination with cookies to track activity
        by a particular browser on a particular device. We may incorporate
        pixels from separate entities, including Facebook and GetEmail, that
        allow us to track our conversions, bring you advertising off the Service
        (such as by associating an email address with the browser or device used
        to access the Service), and provide you with additional functionality,
        such as the ability to connect our Service with your social media
        account.
      </p>
      <p className="pb-3">
        Analytics: We use analytics services, such as Google Analytics, a web
        analytics service provided by Google, Inc. (“Google”). These analytics
        services use cookies to help us analyze how users use the Site and
        enhance your experience when you use the Site. For more information on
        how Google uses this data, go to
        www.google.com/policies/privacy/partners/. To opt out of the use of
        certain information collected by Google Analytics visit
        https://tools.google.com/dlpage/gaoptout and
        https://www.google.com/settings/ads/onweb/ for Google Analytics for
        Display Advertising or the Google Display Network at.
      </p>
      <p className="pb-3">
        Online Tracking and Do Not Track Signals: We and our analytics and
        advertising providers, including Facebook, may use cookies, pixels or
        other tracking technologies to collect information about your browsing
        activities over time and across different websites following your use of
        the Site and use that information to send targeted advertisements. For
        example, we place ads through Google and Facebook that you may view on
        their platforms as well as on other websites and services.
      </p>
      <p className="pb-3">
        We also use audience matching services (which is a type of targeted
        advertising) to reach people (or people similar to people) who have
        visited our Service or are identified in one or more of our databases
        (“Matched Ads”). This is done by us providing a list of hashed email
        addresses to an ad partner or incorporating a pixel from an ad partner
        into our own Service, and the ad partner matching common factors between
        our data and their data. For instance, we incorporate the Facebook pixel
        on our Service and may share your email address with Facebook as part of
        our use of Facebook Custom Audiences
      </p>
      <p className="pb-3">
        The parties we work with to provide you with targeted advertising may
        act as our service providers, or in certain contexts, independently
        decide how to process your information. We encourage you to familiarize
        yourself with and consult their privacy policies and terms of use.
      </p>
      <p className="pb-3">
        Our Site currently does not respond to “Do Not Track” (“DNT”) signals
        and operates as described in this Privacy Policy whether or not a DNT
        signal is received. If we do respond to DNT signals in the future, we
        will update this Privacy Policy to describe how we do so. For more
        information on DNT, visit http://www.allaboutdnt.com.
      </p>
      <p className="pb-3">2. HOW WE USE INFORMATION</p>
      <p className="pb-3">
        We collect and use information for business and commercial purposes in
        accordance with the practices described in this Privacy Policy. We use
        the information we collect for the following business purposes:
      </p>
      <p>
        • To provide the Service, including the online marketplace in which
        Sellers can sell food items and meal orders to customers (each, a
        “Meal”) and customers can rate and provide feedback on those Meals;
      </p>
      <p>• To respond to your inquiries, comments, feedback or questions;</p>
      <p>• To facilitate your orders on the Service;</p>
      <p>
        • To send administrative information to you, for example, information
        regarding the Service, requests for feedback, and changes to our terms,
        conditions, and policies;
      </p>
      <p>• To analyze how you interact with our Service;</p>
      <p>
        • To maintain and improve the content and functionality of the Service
        and other HAPPYOMEALS websites, apps, marketing efforts, products, and
        services;
      </p>
      <p>• To develop new products and services;</p>
      <p>
        • To prevent fraud, criminal activity, or misuses of our Service, breach
        of our policies and terms, and to ensure the security of our IT systems,
        architecture and networks;
      </p>
      <p>
        • To conduct promotions, including verifying your eligibility and if
        applicable, delivering prizes in connection with your entries.
      </p>
      <p>
        • To comply with legal obligations and legal process and to protect our
        rights, privacy, safety or property, and/or that of our affiliates, you
        or other third parties;
      </p>
      <p>• To fulfil any other purpose at your direction; and</p>
      <p className="pb-4">• With notice to you and your consent.</p>
      <p className="pb-3">
        Aggregated or De-Identified Information. We may aggregate or de-identify
        information in a form that does not identify you and use such aggregated
        or de-identified information for any purpose except as prohibited by
        law.
      </p>
      <p className="pb-3">
        Marketing. We use your information to contact you to tell you about
        products or services we believe may be of interest to you. For instance,
        if you elect to provide your email address or provide your consent to
        marketing communications sent to your telephone number we use that
        information to send you special offers. You may opt out of receiving
        promotional emails by following the instructions contained in each
        promotional email we send you. You can also control the marketing emails
        and/or text messages you receive by updating your settings through your
        account. You can opt-out of receiving text messages or calls to your
        phone number at any time by (i) for text messages, texting “STOP” in
        response to any text message you receive from us; and (ii) for calls,
        requesting opt-out during any call you receive from us. In addition, if
        at any time you do not wish to receive future marketing communications
        via your email address or telephone number, you may contact us at
        privacy@HAPPYOMEALS.com. If you unsubscribe from our marketing lists,
        you will no longer receive marketing communications but we may still
        contact you regarding management of your account, other administrative
        matters, and to respond to your requests.
      </p>
      <p className="pb-3">3. SHARING AND DISCLOSURE OF INFORMATION</p>
      <p className="pb-3">
        We share your information that we collect in accordance with the
        practices described in this Privacy Policy. The categories of parties
        with whom we share information is set forth below:
      </p>
      <p>
        • Service Providers: To assist us in meeting business operations needs
        and to perform certain services and functions, we share information with
        service providers, including providers of hosting services, cloud
        services and other information technology services providers, email
        communication software and email newsletter services, payment
        processors, customer relationship management and customer support
        services, and web analytics services (for more details on the parties
        that place cookies through the Site, please see the “Cookies” and
        “Analytics” sections above). Pursuant to our instructions, these parties
        will access, process or store information in the course of performing
        their duties to us, although we may permit them to use information that
        does not identify you (including information that has been aggregated or
        de-identified) for any purpose except as prohibited by applicable law.
      </p>
      <p>
        • Sellers: We share information with sellers on the Service (each , a
        “Seller”) in order to facilitate your orders, provide Sellers your
        feedback and ratings on their Meals, respond to your questions, market
        and advertise to you, and otherwise comply with the law.
      </p>
      <p>
        • Vendors and Other Parties: We share information with vendors and other
        parties for analytics, advertising, delivery and marketing related
        services. These parties may act as our service providers, or in certain
        contexts, independently decide how to process your information
      </p>
      <p>
        • Other Users of the HAPPYOMEALS Website: In certain jurisdictions where
        third party delivery may be restricted or limited, we share information
        with other users of the Service who need access to your information (for
        example your home address and telephone number) in order to complete an
        order (i.e. pick up a meal, deliver a meal, etc. ). If you receive
        another user’s information, you agree to use it only for the purpose in
        which it was provided (i.e. to complete the transaction).
      </p>
      <p>
        • Partners: We share information with our partners in connection with
        offering co-branded services, selling or distributing our products, or
        engaging in joint marketing activities.
      </p>
      <p>
        • Promotions: Our promotions may be jointly sponsored or offered by
        other parties. When you voluntarily enter a promotion, we share
        information as set out in the official rules that govern the promotion
        as well as for administrative purposes and as required by law (e.g., on
        a winners list). By entering a promotion, you agree to the official
        rules that govern that promotion, and may, except where prohibited by
        applicable law, allow the sponsor and/or other entities to use your
        name, voice and/or likeness in advertising or marketing materials.
      </p>
      <p>
        • Profile and Feedback: We share information you make public through the
        Service, such as information in your profile (e.g., your profile
        picture) or your reviews of the Sellers and Meals on the Service. Please
        think carefully before making information public as you are solely
        responsible for any information you make public. Once you have posted
        information, you may not be able to edit or delete such information,
        subject to additional rights set out in this Privacy Policy.
      </p>
      <p>
        • Business Transfers: If we are involved in a merger, acquisition,
        financing due diligence, reorganization, bankruptcy, receivership, sale
        of all or a portion of our assets, or transition of service to another
        provider, information will be shared in the diligence process with
        counterparties and others assisting with the transaction and transferred
        to a successor or affiliate as part of that transaction along with other
        assets.
      </p>
      <p>
        • Security, Safety and Legal Requirements: We share information we
        collect if required to do so by law or in the good faith belief that
        such action is necessary to (i) comply with a legal obligation,
        including to meet national security or law enforcement requirements,
        (ii) protect and defend our rights or property, (iii) prevent fraud,
        (iv) act in urgent circumstances to protect the rights, property, life,
        health, security, and personal safety of us, the Service, users of the
        Service, anyone else, or the public, or (v) protect against legal
        liability. We review all government demands to ensure they are legally
        valid and appropriate. If we receive a demand for your information, to
        the extent permitted by law we will direct the requesting party to seek
        the data directly from you. If legally required or compelled to disclose
        or give access to your information, we will promptly notify you and
        provide a copy of the demand unless legally prohibited from doing so. In
        California, to the extent the Microenterprise Home Kitchen Operation law
        requires that we disclose information about MEHKO permit holders to the
        agency who oversees such permits (including customer complaints and /or
        customer lists), we will disclose such information as required by law.
      </p>
      <p>
        • Affiliates: We may share information with our affiliates, meaning an
        entity that controls, is controlled by, or is under common control with
        HAPPYOMEALS. Our affiliates may use the information we share in a manner
        consistent with this Privacy Policy, including where they act as our
        service providers or for their own internal purposes.
      </p>
      p• Facilitating Requests: We share information as requested by you or at
      your direction.
      <p className="pb-3">
        • Consent: We share information with notice to you and your consent.
      </p>
      <p className="pb-3">
        Notwithstanding the above, we may share information that does not
        identify you (including information that has been aggregated or
        de-identified) except as prohibited by applicable law. For information
        on your rights and choices regarding how we share information about you,
        please see the “Your Rights and Choices” section below.
      </p>
      <p className="pb-3">4. YOUR RIGHTS AND CHOICES</p>
      <p className="pb-3">
        On most web browsers, you will find a “help” section on the toolbar.
        Please refer to this section for information on how to receive a
        notification when you are receiving a new cookie and how to turn cookies
        off. Please see the links below for guidance on how to modify your web
        browser's settings on the most popular browsers:
      </p>
      <p>• Internet Explorer</p>
      <p>• Mozilla Firefox</p>
      <p>• Google Chrome</p>
      <p className="pb-3">• Apple Safari </p>
      <p className="pb-3">
        Please note that if you limit the ability of websites to set cookies,
        you may be unable to access certain parts of the Site and you may not be
        able to benefit from the full functionality of the Site.
      </p>
      <p className="pb-3">
        Advertising networks may collect information through the use of cookies.
        Most advertising networks offer you a way to opt out of targeted
        advertising. If you would like to find out more information, please
        visit the Network Advertising Initiative's online resources at
        http://www.networkadvertising.org and follow the opt-out instructions
        there. For opt-out instructions for companies that participate in the
        Digital Advertising Alliance, please visit: (i) for website targeted
        ads, https://www.aboutads.info/choices; and (ii) for app targeted ads,
        https://www.aboutads.info/appchoices. Opting out only means that the
        selected participants should no longer deliver certain targeted ads to
        you, but does not mean you will no longer receive any targeted content
        and/or ads (e.g., in connection with the participants’ other customers
        or from other technology services).
      </p>
      <p className="pb-3">
        To opt out of us sharing your data with a third party as part of a
        customer list for Matched Ads, please contact us as set forth in the
        “Contact Us” section below and specify that you wish to opt out of
        Matched Ads. We will remove your data from any subsequent lists shared
        with third parties for Matched Ads. Alternatively, you may directly
        contact the applicable party to opt out.
      </p>
      <p className="pb-3">
        You may also limit our use of information collected from or about your
        mobile device for purposes of serving targeted ads to you by going to
        your device privacy settings and turning off “Tracking” and “Apple
        Advertising” (for iOS devices) or “Opt out of Interest-Based Ads” (for
        Android devices).
      </p>
      <p className="pb-3">
        Please note that if you opt out using any of these methods, the opt out
        will only apply to the specific browser or device from which you opt
        out. We are not responsible for the effectiveness of, or compliance
        with, any opt out options or programs, or the accuracy of any other
        entities’ statements regarding their opt out options or programs.
      </p>
      <p className="pb-3">5. DATA RETENTION AND DELETION</p>
      <p className="pb-3">
        We keep information for as long as reasonably necessary for the purposes
        described in this Privacy Policy, while we have a business need to do
        so, or as required by law (e.g. for tax, legal, accounting or other
        purposes), whichever is the longer period of time.
      </p>
      <p className="pb-3">
        Upon your request, we will take steps to delete the information we have
        collected from you; however, we may retain information that we have a
        legal obligation or business need to maintain so long as is reasonably
        necessary. You may request deletion of your information by emailing us
        at privacy@myHAPPYOMEALS.com.
      </p>
      <p className="pb-3">6. UPDATE YOUR INFORMATION</p>
      <p className="pb-3">
        Please log in to your account or contact us at privacy@HAPPYOMEALS.com
        if you need to change, remove, or correct your information. Please note
        that we will retain and use information about you as necessary to comply
        with our legal obligations, resolve disputes, and enforce our
        agreements. If you are a California resident, please see the additional
        disclosures at the end of this Privacy Policy.
      </p>
      <p className="pb-3">7. CHILDREN</p>
      <p className="pb-3">
        Our Service is not directed to children and is intended for a general
        audience. HAPPYOMEALS does not knowingly collect personal information
        (as defined by the U.S. Children’s Online Privacy Protection Act, or
        “COPPA”) from children. If you are a parent or guardian and have reason
        to believe that your child has provided information to HAPPYOMEALS
        through the Service please contact us at privacy@HAPPYOMEALS.com and we
        will endeavor to delete that information from our databases in
        accordance with COPPA.
      </p>
      <p className="pb-3">8. LINKS TO OTHER WEBSITES</p>
      <p className="pb-3">
        The Service may contain links to other websites not operated or
        controlled by HAPPYOMEALS, including social media services. The
        information that you share with and that is collected by these other
        parties will be governed by the specific privacy policies and terms of
        service of the other party and not by this Privacy Policy. By providing
        these links we do not imply that we endorse or have reviewed these
        sites. Please contact the other party directly for information on their
        privacy practices and policies.
      </p>
      <p className="pb-3">9. SECURITY</p>
      <p className="pb-3">
        You use the Service at your own risk. We implement and maintain
        commercially reasonable technical, administrative, and organizational
        measures to protect information about you both online and offline from
        loss, misuse, and unauthorized access, disclosure, alteration or
        destruction. However, no Internet or e-mail transmission is ever fully
        secure or error free. In particular, e-mail sent to or from us may not
        be secure. Therefore, you should take special care in deciding what
        information you send to us via the Service or e-mail. Please keep this
        in mind when disclosing any information to HAPPYOMEALS via the Internet.
      </p>
      <p className="pb-3">10. CHANGES TO THE PRIVACY POLICY</p>
      <p className="pb-3">
        We may update this Privacy Policy at any time. When we do we will post
        an updated version on this page, unless another type of notice is
        required by the applicable law. By continuing to use our Service or
        providing us with information after we have posted an updated Privacy
        Policy, or notified you by other means if applicable, you consent to the
        then posted Privacy Policy and practices described in it.
      </p>
      <p className="pb-3">11. CONTACT US</p>
      <p className="pb-3">
        If you have any questions about our Privacy Policy or information
        practices, please feel free to contact us at privacy@HAPPYOMEALS.com.
      </p>
      <p className="pb-3">
        This Privacy Policy has been designed to be accessible to people with
        disabilities. If you experience any difficulties accessing the
        information here, please contact us at privacy@HAPPYOMEALS.com
      </p>
      <p className="pb-3">
        We may require specific information from you to help us verify your
        identity and process your request. If we are unable to verify your
        identity, we may deny your request.
      </p>
      <p className="pb-3">California Shine the Light.</p>
      <p className="pb-3">
        If you are a California resident you may request (i) a list of the
        categories of personal information disclosed by us in the immediately
        preceding calendar year to third parties for their own direct marketing
        purposes; and (ii) a list of the categories of third parties to whom we
        disclosed such information. To exercise a request, please email
        HAPPYOMEALS at privacy@HAPPYOMEALS.com and clearly state you’re making a
        “California Shine the Light” request, including your name, mailing
        address, email address and phone number. We may require additional
        information from you to allow us to verify your identity and are only
        required to respond to requests once during any calendar year.
      </p>
      <p className="pb-3"></p>
      <p className="pb-3"></p>
      <p className="pb-3"></p>
      <p className="pb-3"></p>
      <p className="pb-3"></p>
      <p className="pb-3"></p>
      <p className="pb-3"></p>
    </div>
  );
}

export default PrivacyPolicy;
