import React from "react";

function TermsNConditions() {
  return (
    <div className=" max-w-[1080px] px-5 py-3 text-sm mx-auto ">
      <h1 className="text-2xl pb-3 text-red-500 text-center">
        Terms of Service
      </h1>
      <p className="pb-3">
        HOMEALS INDIA PVT LTD, Inc. HOMEALS INC USA ("HAPPYOMEALS" ,"we," "us,"
        "our") makes certain content available and/or provides its services
        (described below) to you through the HAPPYOMEALS website (the "Site")
        and through its mobile applications, technology platform, and related
        services (collectively, such services, including any new features and
        applications, and the Site, the "Service"), subject to the following
        Terms of Service (as amended from time to time, the "Terms of Service").
        These Terms of Service form a legally binding agreement ("the
        Agreement") between you and HAPPYOMEALS, its parents, subsidiaries,
        representatives, affiliates, officers, and directors governing your use
        of the Service. We reserve the right, at our sole discretion, to change
        or modify portions of these Terms of Service at any time. If we do this,
        we will post the changes on this page and will indicate on this page the
        date these terms were last revised. Any such changes will become
        effective immediately upon your acceptance of the modified Agreement.
        Your continued use of the Service after the date any such changes become
        effective constitutes your acceptance of the new Terms of Service.
        Unless material changes are made to the arbitration provisions herein,
        you agree that modification of this Agreement does not create a renewed
        opportunity to opt out of arbitration (if applicable). If you do not
        agree to abide by these or any future Terms of Service, do not use or
        access (or continue to use or access) the Service.
      </p>
      <p className="pb-3">
        PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN AN
        AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR
        LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE
        REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE
        AGAINST US TO BINDING AND FINAL INDIVIDUAL ARBITRATION, AND FURTHER (1)
        YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST HAPPYOMEALS ON AN
        INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
        REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO
        SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON
        AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT HAVE ANY CLAIMS YOU HAVE
        AGAINST US RESOLVED BY A JURY OR IN A COURT OF LAW. BY ACKNOWLEDGING THE
        TERMS OF SERVICE AND/OR USING THE HAPPYOMEALS SITE, YOU EXPRESSLY
        ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE WITHOUT LIMITATION
        OR QUALIFICATION, TO BE BOUND BY THIS AGREEMENT AND YOU ACCEPT ALL OF
        ITS TERMS.
      </p>
      <p className="pb-3">
        In addition, when using certain services, you will be subject to any
        additional terms applicable to such services that may be posted on the
        Service from time to time, including, without limitation, the Privacy
        Policy located at https://HAPPYOMEALS.com/privacy/. Sellers are also
        subject to Seller Community Guidelines. All such terms are hereby
        incorporated by reference into and subject to these Terms of Service.
      </p>
      <p className="pb-3">Description of the Service</p>
      <p className="pb-3">
        The Service is a technology platform that provides an online marketplace
        which enables connections between personal chefs, caterers and other
        individuals who wish to list, offer, sell, and deliver food items and
        meal orders ("Meal(s)") to customers who browse and purchase Meals.
        Users who purchase Meals through the Service are "Customer(s)," and
        users who list, prepare and sell Meals through the Service are
        "Seller(s)." A user may be both a Customer and Seller. All purchases are
        made directly (and any contract for purchase and sale is) between the
        Customer and Seller.
      </p>
      <p className="pb-3">
        HAPPYOMEALS offers a marketplace—it is not itself a Seller and is not
        preparing, packaging, or selling any food. The Service includes enabling
        users to connect with each other and related services, but we are not a
        party to any purchase or sales transaction. To encourage return use of
        the Service, HAPPYOMEALS may also help facilitate the resolution of
        disputes between Customers and Sellers, but HAPPYOMEALS has no control
        over and does not guarantee (a) the existence, quality, safety,
        authenticity, or legality of Meals offered or sold on the Service; (b)
        the truth or accuracy of a Seller's content or listings on the Service;
        (c) the ability of a Seller to sell Meals through the Service and
        deliver Meals within required delivery windows; (d) the ability of
        Customers to pay for Meals purchased through the Service; or (e) that a
        Customer or Seller will actually complete a transaction, effectuate
        trouble-free delivery and shipping, or return a Meal through the
        Service.
      </p>
      <p className="pb-3">
        Sellers are independent business owners and independent contractors to
        Customers and not employees, partners, representatives, agents, joint
        venturers, or franchisees of HAPPYOMEALS. HAPPYOMEALS does not cook,
        package or deliver food and does not employ people to cook, package or
        deliver food.
      </p>
      <p className="pb-3">
        Each Seller is solely responsible for complying with all applicable
        laws, rules and regulations and standards, including but not limited to
        those pertaining to the preparation, sale, marketing, packaging,
        handling, and delivery of all Meals ordered through HAPPYOMEALS, and
        updating details and prices relating to the Meals offered. Each Seller
        is solely liable for the quality, safety, and freshness of its products,
        and HAPPYOMEALS does not verify the credentials, representations,
        products, services, or prices offered by any Sellers, and does not
        guarantee the quality of the product or services, or that Sellers or
        Meals comply with applicable laws. HAPPYOMEALS will not be liable or
        responsible for any Meals provided by Sellers that are a cause of injury
        or that do not meet your expectations in any manner.
      </p>
      <p className="pb-3">
        Depending on the jurisdiction, HAPPYOMEALS may enter into agreements
        with third party independent contractors (each a "Courier") to provide
        delivery services under certain circumstances if desirable by individual
        Sellers or Customers. If a Seller or Customer chooses to use a Courier
        to deliver a Meal, HAPPYOMEALS is not offering such delivery services
        and has no responsibility or liability for the actions or inactions of
        any Courier. HAPPYOMEALS will not be liable or responsible for any
        delivery services provided by a Courier or any errors or
        misrepresentations made by them. Couriers are required to comply with
        all applicable laws, rules and regulations.
      </p>
      <p className="pb-3">Access to the Service</p>
      <p className="pb-3">
        You may be required to register with HAPPYOMEALS in order to access and
        use certain features of the Service. If you choose to register for the
        Service, you agree to provide and maintain true, accurate, current and
        complete information about yourself as prompted by the Service's
        registration form. Registration data and certain other information about
        you are governed by our Privacy Policy. The Service is available only to
        individuals who can form legally binding contracts under applicable law.
        Without limiting the foregoing, the Service is not available to minors
        (people under the age of majority in their state or province of
        residence) or to temporarily or permanently suspended users of the
        Service (both Customers and Sellers). If you do not qualify, please do
        not use the Service. Additionally, HAPPYOMEALS reserves the right to
        refuse access to, or use of the Service to, anyone who breaches these
        Terms of Service or for the reasons related to the safety of the
        HAPPYOMEALS Community including HAPPYOMEALS Users, employees, or
        prospective Users. Except as expressly authorized otherwise by
        HAPPYOMEALS, you are only authorized to create and use one account for
        the Service and are prohibited from using alter egos or other disguised
        identities when using the Service
      </p>
      <p className="pb-3">
        You are responsible for maintaining the confidentiality of your password
        and account, if any, and are fully responsible for any and all
        activities that occur under your password or account. You agree to (a)
        immediately notify HAPPYOMEALS of any unauthorized use of your password
        or account or any other breach of security, and (b) ensure that you exit
        from your account at the end of each session when accessing the Service.
        HAPPYOMEALS will not be liable for any loss or damage arising from your
        failure to comply with this Section.
      </p>
      <p className="pb-3">
        HAPPYOMEALS reserves the right to modify or discontinue, temporarily or
        permanently, the Service (or any part thereof) with or without notice.
        You agree that HAPPYOMEALS shall not be liable to you or to any third
        party for any modification, suspension or discontinuance of the Service.
      </p>
      <p className="pb-3">
        You acknowledge that HAPPYOMEALS may establish general practices and
        limits concerning use of the Service. You agree that HAPPYOMEALS has no
        responsibility or liability for the deletion or failure to store any
        data or other content maintained or transmitted by the Service. You
        acknowledge that HAPPYOMEALS reserves the right to terminate accounts
        that are inactive for an extended period of time. You further
        acknowledge that HAPPYOMEALS reserves the right to change these general
        practices and limits at any time, in its sole discretion, with or
        without notice.
      </p>
      <p className="pb-3">
        The Service may include certain services that are available via a mobile
        device, including (i) the ability to upload content (including Meal
        options or Seller or Customer information) to the Service via a mobile
        device, (ii) the ability to browse the Service and the Site from a
        mobile device and (iii) the ability to access certain features through
        an application downloaded and installed on a mobile device
        (collectively, the "Mobile Services"). To the extent you access the
        Service through a mobile device, your wireless service carrier's
        standard charges, data rates and other fees may apply. In addition,
        downloading, installing, or using certain Mobile Services may be
        prohibited or restricted by your carrier, and not all Mobile Services
        may work with all carriers or devices. In the event you change or
        deactivate your mobile telephone number, you agree to promptly update
        your HAPPYOMEALS account information to ensure that your messages are
        not sent to the person that acquires your old number. You acknowledge
        and agree that your use of the Service must be in accordance with the
        usage rules established by your mobile device platform or service
        provider.
      </p>
      <p className="pb-3">
        By registering for and using the Service, including the Mobile Services,
        and providing your contact information, you are consenting to be
        contacted by HAPPYOMEALS for marketing and/ or transactional purposes by
        telephone, text message (which may be automated), email, fax, or other
        means. By using the Mobile Services, you also agree that HAPPYOMEALS may
        communicate with you by any electronic means to your mobile device and
        that certain information about your usage of the Mobile Services may be
        communicated to us. In the event you no longer want to receive
        communications from HAPPYOMEALS, you agree to notify HAPPYOMEALS
        directly. You may opt-out of text messages by texting STOP in response
        to any text message you receive from us and for calls, you may instruct
        the caller that you are opting out during any call you receive from us.
        You may also adjust your notification settings on the Notification page
        in your account settings. If you unsubscribe from our marketing lists,
        you will no longer receive marketing communications, but we may still
        contact you (including by text message) regarding management of your
        account, other administrative matters and to respond to your requests.
      </p>
      <p className="pb-3">Conditions of Use; Acceptable Use</p>
      <p className="pb-3">
        You are solely responsible for all descriptions, pictures, listings,
        information, data, text, music, sound, graphics, video, messages, or
        other materials ("content") that you upload, post, publish, or display
        (hereinafter, "upload") or email or otherwise send via the Service. You
        are also solely responsible for the manner in which you access and use
        the Service. HAPPYOMEALS reserves the right to investigate and take
        appropriate legal action against anyone who, in HAPPYOMEALS's sole
        discretion, violates this provision, including without limitation,
        removing offending content from the Service, suspending or terminating
        the account of such violators, and reporting you to the law enforcement
        authorities. The following are examples of the kind of content and/or
        use of the Service that is illegal and/or prohibited by HAPPYOMEALS. You
        agree not to use the Service to: a) sell, post or otherwise transmit any
        content or information that (i) is unlawful, harmful, threatening,
        abusive, harassing, tortious, excessively violent, defamatory, vulgar,
        obscene, pornographic, libelous, invasive of another‘s privacy, hateful,
        or otherwise objectionable; (ii) you do not have a right to sell or
        transmit under law or under contractual or fiduciary relationships;
        (iii) poses or creates a privacy or security risk to any person; (iv)
        infringes any intellectual property or other proprietary rights of any
        party; (v) constitutes unsolicited, unauthorized, or misleading
        advertising, promotional materials, commercial activities and/or sales,
        "junk mail," "spam," "contests," "sweepstakes," or any other form of
        solicitation; (vi) contains software viruses or any other computer code,
        files or programs designed to interrupt, destroy or limit the
        functionality of any computer software or hardware or telecommunications
        equipment; or (vii) in the sole judgment of HAPPYOMEALS, is
        objectionable or which restricts or inhibits any other person from using
        or enjoying the Service, or which may expose HAPPYOMEALS or its users to
        any harm or liability of any type; b) impersonate any person or entity,
        or falsely state or otherwise misrepresent your affiliation with a
        person or entity; c) solicit personal information from anyone under the
        age of 18; d) harvest or collect email addresses or other contact
        information of other users from the Service or the Site by electronic or
        other means for the purposes of sending unsolicited emails or other
        unsolicited communications; e) advertise or offer to sell or buy any
        goods or services other than Meals intended to be prepared, sold and
        purchased through the Service; f) use the Service to request, make or
        accept a Meal independent of the Service, to circumvent any fees that
        would otherwise apply to such transaction; g) interfere with or disrupt
        the Service or servers or networks connected to the Service, or disobey
        any requirements, procedures, policies or regulations of networks
        connected to the Service; h) violate any applicable local, state,
        national or international law, or any regulations having the force of
        law; i) engage in any activities that violate the Telephone Consumer
        Protection Act, 47 U.S.C. § 227 et seq. and its regulations at 47 C.F.R.
        § 64.1200; the Do-Not-Call Implementation Act, 15 U.S.C. § 6101 et seq.;
        or any similar anti-spam, data protection, or privacy legislation in any
        jurisdiction; j) further or promote any criminal activity or enterprise
        or provide instructional information about illegal activities; or k)
        obtain or attempt to access or otherwise obtain any materials or
        information through any means not intentionally made available or
        provided for through the Service.
      </p>
      <p className="pb-3">
        Ingredients and Allergens: For any Meal and related content posted using
        the Service, a Seller is required to provide a comprehensive list of
        ingredients contained in or otherwise used to prepare the Meal,
        including any known allergens. HAPPYOMEALS is not responsible for the
        accuracy of the information and labeling of Meals delivered to
        Customers. Customers should be advised that Meals may be prepared in
        facilities using the same equipment that is used to prepare Meals
        containing other allergens even if the allergen is marked as being
        absent from the food. You should not use this service if you have food
        allergies. HAPPYOMEALS shall not be liable for any illness, health
        problem, or other damages that may result from any order or consumption
        of any Meals and related items purchased through the Service.
      </p>
      <p className="pb-3">
        Seller Insurance: HAPPYOMEALS maintains insurance for its own business
        operations, but it does not maintain insurance for the actions of
        Sellers or Customers. If you are a Seller, HAPPYOMEALS recommends that
        you obtain appropriate insurance to cover the operation of your food
        facility and the preparation and delivery of Meals. Please review any
        respective insurance policy carefully, and in particular make sure that
        you are familiar with and understand any exclusions to, and any
        deductibles that may apply for, such insurance policy, including, but
        not limited to, whether or not your insurance policy will cover the
        actions or inactions of Customers or any third-party service.
      </p>
      <p className="pb-3">
        Customer Review Guidelines: To the extent you are a Customer,
        HAPPYOMEALS may allow you to upload content and leave reviews regarding
        your purchase of Meals. HAPPYOMEALS may accept, reject or remove reviews
        and any associated content in its sole discretion. HAPPYOMEALS has
        absolutely no obligation to screen or to delete reviews or associated
        content, even if anyone considers reviews objectionable or inaccurate.
        Those Customers posting reviews should comply with the following
        criteria: (1) reviewers should have firsthand experience with the
        person/entity being reviewed; (2) reviews should not contain: offensive
        language, profanity, or abusive, racist, or hate language;
        discriminatory references based on religion, race, gender, national
        origin, age, marital status, sexual orientation or disability; or
        references to illegal activity; (3) reviewers should not be affiliated
        with competitors if posting negative reviews; (4) reviewers should not
        make any conclusions as to the legality of conduct;; and (5) reviewers
        may not post any false statements or organize a campaign encouraging
        others to post reviews, whether positive or negative. Reviews are not
        endorsed by HAPPYOMEALS, and do not represent the views of HAPPYOMEALS
        or of any affiliate or partner of HAPPYOMEALS. HAPPYOMEALS does not
        assume liability for any review or for any claims, liabilities or losses
        resulting from any review. Any review you post will be considered User
        Content (as defined below).
      </p>
      <p className="pb-3">
        Marketplace Risk: HAPPYOMEALS is not affiliated with or endorsed by any
        Seller, restaurant or other meal preparation service made available
        through the Service. Beyond its capacity as a marketplace and technology
        platform, HAPPYOMEALS is not a party to or involved in any transaction
        between Customers and Sellers, and does not act as a Customer, Seller
        (or affiliate for a Customer or Seller) or broker with respect to any
        transactions. There are risks that you assume when dealing with other
        users (including those who may be acting under false pretenses) and all
        of these risks are borne by you, and not HAPPYOMEALS. We encourage you
        to use the various functionalities of the Service (e.g., Seller
        profiles, meal options, and user reviews) to help evaluate the user with
        whom you are dealing.
      </p>

      <p className="pb-3">
        Sellers as Independent Business Owners / Independent Contractors: Users
        of the Service acting in the capacity of a Seller do so in their
        personal capacity as an independent business and independent contractor
        and seller of goods and not as an employee of HAPPYOMEALS. As a Seller,
        you agree that: (i) you are an independent business owner and not an
        employee of HAPPYOMEALS; (ii) you shall be solely responsible for
        determining the means and manner of the work performed to create the
        goods you sell through the Marketplace; (iii) these Terms of Service do
        not create an association, joint venture, partnership, franchise, or
        employer/employee relationship between you and HAPPYOMEALS, or you and a
        Customer; (iv) you will not represent yourself as an employee or agent
        of HAPPYOMEALS to any Customer; (v) you will not be entitled to any of
        the benefits that HAPPYOMEALS may make available to its employees, such
        as vacation pay, sick leave, and insurance programs, including group
        health insurance or retirement benefits; and (vi) you are not eligible
        to recover contributor's compensation benefits in the event of injury.
        As a Seller, you will not engage a Customer in any way that may
        jeopardize your status as an independent business.
      </p>
      <p className="pb-3">
        HAPPYOMEALS does not control the behavior of users of the Service, or
        the information or User Content (defined below) provided by other users.
        As a result, HAPPYOMEALS does not guarantee or endorse the legality,
        authenticity, quality, or safety of any Meals offered or sold, the truth
        or accuracy of any listings, or the ability of Sellers to sell Meals or
        of Customers to buy Meals. We cannot assure that all transactions will
        be completed. Additionally, HAPPYOMEALS does not guarantee the ability
        or intent of users to fulfill their obligations in any transactions.
        HAPPYOMEALS reserves the right to delay the completion of any
        transaction for a reasonable period of time for purposes of fraud
        detection and otherwise protecting HAPPYOMEALS and its Users from
        illegal or wrongful activities or other violations of these Terms of
        Services. When interacting with other Users, you should exercise caution
        and common sense to protect your personal safety, data, and property,
        just as you would when interacting with other persons whom you don’t
        know. HAPPYOMEALS will not be liable for any false or misleading
        statements made by Users of the HAPPYOMEALS Platform.
      </p>
      <p className="pb-3">
        Special Notice for International Use; Export Controls: Software (defined
        below) available in connection with the Service and the transmission of
        applicable data, if any, is subject to United States export controls. No
        Software may be downloaded from the Service or otherwise exported or
        re-exported in violation of U.S. export laws. Downloading or using the
        Software is at your sole risk. Recognizing the global nature of the
        Internet, you agree to comply with all local rules and laws regarding
        your use of the Service, including as it concerns online conduct and
        acceptable content
      </p>
      <p className="pb-3">
        Commercial Use: Unless otherwise expressly authorized herein or in the
        Service, you agree not to display, distribute, license, perform,
        publish, reproduce, duplicate, copy, create derivative works from,
        modify, sell, resell, exploit, transfer or upload for any commercial
        purposes, any portion of the Service, use of the Service, or access to
        the Service.
      </p>
      <p className="pb-3">
        ID Verification; Other Safety Checks. In some locations, the sale and
        delivery of alcohol may be permitted through the Marketplace. In such
        jurisdictions, the Seller must hold a valid liquor license that permits
        the sale of cocktails for delivery, and it is the responsibility of such
        Seller and any delivery provider they use to verify identification and
        age, and to determine whether it is otherwise safe and appropriate to
        furnish their Customers with alcohol. They may require a valid form of
        photo identification with birthdate and a signed acknowledgment
        confirming acceptance at the time of pick-up or delivery. If they cannot
        verify a Customer’s age or identity, if a Customer appears intoxicated,
        or if the situation is otherwise unsafe or inappropriate (for example,
        the Customer is 25 years old but appears to be in the company of only 18
        year olds), they may refuse to furnish the Customer with alcohol.
        Alcohol cannot be left unattended (contactless delivery is not available
        for alcohol orders) — someone 21 or older must be present to accept and
        sign for it. If a HAPPYOMEALS is unable to deliver your product for this
        or a similar reason, the HAPPYOMEALS may still charge you for the order.
        If we believe that your order is fraudulent or unlawful, we may suspend
        your use of the Platform.
      </p>
      <p className="pb-3">User Representations & Warranties</p>
      <p>All Users represent and warrant that:</p>
      <p>
        · You are at least of the legally required age in the jurisdiction in
        which you reside and are otherwise capable of entering into binding
        contracts;
      </p>
      <p>
        · You have the right, authority and capacity to enter into this
        Agreement and to abide by the terms and conditions of this Agreement,
        and that you will so abide;
      </p>
      <p>
        · You have read, understand and agree to be bound by these Terms of
        Service and the Privacy Policy
      </p>
      <p>
        · You will respect the privacy (including without limitation private,
        family and home life) and data protection rights of Users with whom you
        communicate through the HAPPYOMEALS Marketplace
      </p>
      <p>
        · You will fulfill the commitments you make to other Users, communicate
        clearly and respectfully, provide or accept Meals at the agreed time and
        only utilize the third party payment service provider specified or
        approved by the Company to make or receive payment through the
        HAPPYOMEALS Marketplace.
      </p>
      <p>
        · You will act professionally and responsibly in your interactions with
        other Users;
      </p>
      <p>· You will use your real name or business name on your profile;</p>
      <p>
        · When using or accessing the HAPPYOMEALS Marketplace, including when
        preparing, packaging, delivering, providing or receiving meals, you will
        act in accordance with all applicable local, state and federal laws and
        regulations and in good faith;
      </p>
      <p className="pb-3">
        · You do not have any motivation, status, or interest that the Company
        may reasonably wish to know about in connection with the HAPPYOMEALS
        Marketplace, including without limitation, if you are using or will or
        intend to use the HAPPYOMEALS Marketplace for any journalistic,
        academic, investigative, or unlawful purpose.
      </p>
      <p className="pb-3">Sellers additionally represent and warrant that:</p>

      <p>
        · When selling through the HAPPYOMEALS Marketplace you are operating as
        a sole proprietor, partnership, limited liability company, limited
        partnership, corporation or other business entity;
      </p>
      <p>
        · You are engaged in an independently established business selling goods
        similar to the goods you sell to Customers through the HAPPYOMEALS
        Marketplace, and maintain independent customers;
      </p>
      <p>
        · You have the unrestricted right to earn income in the jurisdiction in
        which you will be selling food.
      </p>
      <p>
        · You have and will maintain all necessary licenses or authorizations to
        prepare, package, handle and sell all foods that you sell through the
        HAPPYOMEALS Marketplace.
      </p>
      <p>
        · Your operations, facility, and equipment used to prepare, package,
        handle and sell foods through HAPPYOMEALS will at all times comply with
        all applicable laws and regulations relevant to the location where you
        are preparing food and the type of food you are selling.
      </p>
      <p>
        · You, and any person employed by you, will follow safe practices for
        the handling, preparation, and packaging of food sold through
        HAPPYOMEALS, including but not limited to wearing a face mask, hairnet
        and gloves when cooking and packaging Customer orders.
      </p>
      <p>
        · You have any and all insurance required to operate your business and
        connect with Customers to sell your food through the Marketplace.
      </p>
      <p>
        · All food sold through HAPPYOMEALS are hereby guaranteed, as of the
        date of each drop-off or delivery, to be not adulterated or misbranded
        within the meaning of the Federal Food, Drug and Cosmetic Act, as
        amended, the Federal Meat Inspection Act, as amended, the federal
        Poultry Products Inspection Act, as amended (collectively, the “Federal
        Acts”) or within the meaning of any state or local food law, the
        adulteration and misbranding provisions of which are not the same as
        those found in the Federal Acts.
      </p>
      <p>
        · You have and will maintain food protection manager certification or
        food handler certification from an American National Standards
        Institute/Conference for Food Protection-accredited certifier or an
        equivalent certificate from my local regulatory authority.
      </p>
      <p>
        · You will use your real name or business name and an up-to-date photo
        on your profile;
      </p>
      <p>
        · You will honor your commitments to other Users of the Marketplace,
        including by fulfilling product orders on time and as agreed upon with
        your Customer and providing timely, high-quality food to your Customers;
      </p>
      <p>
        · You will only prepare, package for delivery and sell food safely and
        in accordance with all applicable laws.
      </p>
      <p>
        · For each item you sell, you will disclose each and every ingredient to
        your Customers including but not limited to each major allergen as
        defined by local, state or federal law;
      </p>
      <p>
        · Your operations, facility, and equipment used to prepare, package,
        handle and sell foods through HAPPYOMEALS’s platform will at all times
        comply with all applicable laws and regulations relevant to the location
        where you are preparing food and the type of food you are selling.
      </p>
      <p>
        · You agree that we may (but are not required to) obtain information
        about you, including your criminal records, and you agree to provide any
        further necessary authorizations to facilitate our access to such
        records during the term of the Agreement;
      </p>
      <p>
        · You will pay all applicable federal, state and local taxes based on
        income earned for your sale of food (note, the platform will collect and
        remit taxes for the sale of food where applicable);
      </p>
      <p>
        · You agree not to market your meals in a false or misleading manner
        including but not limited to not making false representations or claims
        about the nutritional value of your meals.
      </p>
      <p>
        · You have read and agree to comply with the HAPPYOMEALS community
        guidelines to maintain the health and safety of the entire marketplace.
      </p>
      <p className="pb-3">
        · You agree not to cook while ill and comply with all local, state, and
        federal COVID guidelines or requirements.
      </p>

      <p className="pb-3">
        For Sellers located in USA, you represent and warrant that you prepare
        and provide all Meals to Customers using a qualified (i) "Food Facility"
        (as defined in Section 113789 of the California Retail Food Code, as
        amended); (ii) "Food Processing Establishment" (as defined in Section
        111955 of the California Retail Food Code, as amended); or (iii)
        "Cottage Food Operation" (as defined in Section 113758 of the California
        Retail Food Code, as amended). To the extent you are a Seller preparing
        and providing Meals out of a food facility qualifying only as a
        "Microenterprise Home Kitchen" (as defined in Section 113825 of the
        California Retail Food Code, as amended), you further represent and
        warrant that you are in compliance with the terms of Chapter 11.6 of the
        California Retail Food Code, as amended, which requires, among other
        things, that: a) You operate your microenterprise home kitchen in a city
        or county where the governing body has authorized by ordinance or
        resolution the permitting of microenterprise home kitchen operations; b)
        You have submitted to the local enforcement agency written standard
        operating procedures that include the following information: (i) all
        food types or products that will be handled, (ii) the proposed
        procedures and methods for food preparation and handling, (iii)
        procedures, methods, and schedules for cleaning utensils, equipment, and
        for the disposal of refuse; (iv) how food will be maintained at the
        required holding temperatures, pending pickup by Customers or during
        delivery; and (v) days and times that the home kitchen will potentially
        be utilized as a microenterprise home kitchen operation. c) You have
        successfully (i) passed an approved and accredited food safety
        certification examination, (ii) passed an inspection and (iii) received
        a permit to operate issued from the local enforcement agency; d) Your
        permit to operate a microenterprise home kitchen operation received from
        the local enforcement agency remains valid and in full effect; e) Any
        person delivering Meals on behalf of your permitted microenterprise home
        kitchen operation is an employee or a family/household member of yours,
        and if such a person drives a motor vehicle to deliver Meals, the person
        has a valid driver's license; f) You have entered (and updated when
        necessary) the permit number for your microenterprise home kitchen
        operation in the dedicated field provided by us during the sign-up
        process; g) You have no more than one full-time equivalent food
        employee, not including a family member or household member; h) Your
        Meals are prepared, cooked and served to Customers on the same day; i)
        Your Meals are consumed either (i) onsite at your microenterprise home
        kitchen or (ii) offsite if the food is picked up by the consumer or
        delivered within a safe time period; j) Your Meal preparation does not
        involve processes that require or handle raw milk or require a HACCP
        plan; k) Your Meals do not include raw oysters; l) You do not prepare
        more than 30 individual Meals per day, and no more than 60 Meals per
        week (or the approximate equivalents of each if meal components are sold
        separately); m) Your operation has no more than fifty thousand dollars
        ($50,000) in verifiable gross annual sales, as adjusted annually for
        inflation based on the California Consumer Price Index; and n) You sell
        Meals only to Customers who are consumers rather than wholesaler or
        retailers.
      </p>
      <p className="pb-3">
        For further information on California's regulations regarding
        microenterprise home kitchens, please review the resources provided by
        the Cook Alliance at www.cookalliance.org. HAPPYOMEALS does not
        guarantee the accuracy of information or endorse any opinions contained
        on the Cook Alliance website.
      </p>
      <p className="pb-3">Purchase and Sale Transactions</p>
      <p className="pb-3">
        Fees: Registering for the Service is free; however, HAPPYOMEALS charges
        certain fees for various transactions effected through the Service, as
        set forth in the fee policy located at
        https://HAPPYOMEALS.com/fee-policy (the "Fee Policy"). Unless otherwise
        stated, all fees are quoted in U.S. dollars.
      </p>
      <p className="pb-3">
        Taxes; Shipping Costs; Customer Responsibility: Meals purchased by and
        delivered to Customers in locations in the INDIA or U.S. may be subject
        to applicable state or local sales or use tax, privilege tax or similar
        transaction-based taxes required in the food services industry of a
        particular location ("Taxes"). The amount of Taxes is based on a number
        of factors, including but not limited to a Customer's designated
        delivery address, and/or the location of the Seller.
      </p>
      <p className="pb-3">
        Customers are responsible for paying to a Seller the applicable purchase
        price for a purchased Meal, as well as any delivery costs and paying
        applicable Taxes associated with the purchase and sale of any Meals
        through the Service. Delivery costs and Taxes attributable to the sale
        of any Meals are not included in the listed price for any Meals listed
        by Sellers through the Service but will be displayed to Customers before
        confirmation of any purchase. Customers also pay a service fee to
        HAPPYOMEALS, as set forth in the Fee Policy.
      </p>
      <p className="pb-3">
        Sellers are responsible for paying HAPPYOMEALS's commission set forth in
        the Fee Policy as well as their own taxes imposed or measured by such
        Seller's net income, net profits, income, profits, and/or revenues
        associated with the sale of any Meals through the Service.
      </p>
      <p className="pb-3">
        Packaging: Sellers may choose to purchase packaging materials and/or
        labels from us for the delivery of Meals prepared for Customers. If
        purchased, we may charge your credit card or other acceptable payment
        method you provide.
      </p>
      <p className="pb-3">
        Payments; Subscriptions: Customers may pay for Items using credit cards
        or other payment methods as may be determined by HAPPYOMEALS from time
        to time. Sellers will be prompted to provide bank account details to us
        so that we may facilitate transfer of payments received from Customers
        purchasing Meals. If you submit your payment information through the
        Service, then you authorize HAPPYOMEALS to store that payment
        information and charge your payment method for any Item you purchase.
        You will promptly update your account information with any changes (for
        example, a change in your billing address or credit card expiration
        date) that may occur.
      </p>
      <p className="pb-3">
        If you purchase Meals on a recurring or subscription basis, you hereby
        authorize HAPPYOMEALS to bill your payment instrument in advance on a
        periodic basis in accordance with the terms of any particular
        subscription order until you terminate or pause such order or terminate
        your account, and you further agree to pay any charges so incurred. If
        you dispute any charges you must let HAPPYOMEALS know within fourteen
        (14) days after the date that HAPPYOMEALS charges you.
      </p>
      <p className="pb-3">
        Fee Modifications: We reserve the right to change or discontinue,
        temporarily or permanently, some or all of the fees for the Service. Any
        change will be effective upon posting of a revised Fee Policy unless a
        different notice period is required by law. Your continued use of the
        Service after the fee change becomes effective constitutes your
        agreement to pay the changed amount.
      </p>
      <p className="pb-3">
        Promotions; Credits: HAPPYOMEALS may from time to time offer Users
        certain promotions, incentives or credits ("Promotions") towards the
        purchase of Meals and/or fees associated with use of the platform, and
        Users may use such Promotions in accordance with their terms. Depending
        on the type of Promotion used, it may reduce the amount of taxes that
        apply to a Customer's order. The application of the Promotion will be
        reflected at the time of checkout and on the receipt of purchase.
        Promotions are personal to the User that they are sent to and may not be
        shared or redeemed with anyone else unless it is explicitly permitted by
        the terms of that Promotion. Sellers may also offer Promotions to their
        customers through the platform and are solely responsible for any costs
        associated with redemption of that Promotion.
      </p>
      <p className="pb-3">Third Party Distribution Channels</p>
      <p className="pb-3">
        HAPPYOMEALS offers Software applications that may be made available
        through the Apple App Store, Android Marketplace or other distribution
        channels ("Distribution Channels"). If you obtain such Software through
        a Distribution Channel, you may be subject to additional terms of the
        Distribution Channel. These Terms of Service are between you and us
        only, and not with the Distribution Channel. To the extent that you
        utilize any other third party products and services in connection with
        your use of our Services, you agree to comply with all applicable terms
        of any agreement for such third party products and services.
      </p>
      <p className="pb-3">
        With respect to Software that is made available for your use in
        connection with an Apple-branded product (such Software, "Apple-Enabled
        Software"), in addition to the other terms and conditions set forth in
        these Terms of Service, the following terms and conditions apply:
      </p>
      <p className="pb-3">
        • HAPPYOMEALS and you acknowledge that these Terms of Service are
        concluded between HAPPYOMEALS and you only, and not with Apple Inc.
        ("Apple"), and that as between HAPPYOMEALS and Apple, HAPPYOMEALS, not
        Apple, is solely responsible for the Apple-Enabled Software and the
        content thereof. • You may not use the Apple-Enabled Software in any
        manner that is in violation of or inconsistent with the Usage Rules set
        forth for Apple-Enabled Software in, or otherwise be in conflict with,
        the App Store Terms of Service. • Your license to use the Apple-Enabled
        Software is limited to a non-transferable license to use the
        Apple-Enabled Software on an iOS Product that you own or control, as
        permitted by the Usage Rules set forth in the App Store Terms of
        Service. • Apple has no obligation whatsoever to provide any maintenance
        or support services with respect to the Apple-Enabled Software. • Apple
        is not responsible for any product warranties, whether express or
        implied by law. In the event of any failure of the Apple-Enabled
        Software to conform to any applicable warranty, you may notify Apple,
        and Apple will refund the purchase price for the Apple-Enabled Software
        to you, if any; and, to the maximum extent permitted by applicable law,
        Apple will have no other warranty obligation whatsoever with respect to
        the Apple-Enabled Software, or any other claims, losses, liabilities,
        damages, costs or expenses attributable to any failure to conform to any
        warranty, which will be HAPPYOMEALS's sole responsibility, to the extent
        it not be disclaimed under applicable law. • HAPPYOMEALS and you
        acknowledge that HAPPYOMEALS, not Apple, is responsible for addressing
        any claims of you or any third party relating to the Apple-Enabled
        Software or your possession and/or use of that Apple-Enabled Software,
        including, but not limited to: (i) product liability claims; (ii) any
        claim that the Apple-Enabled Software fails to conform to any applicable
        legal or regulatory requirement; and (iii) claims arising under consumer
        protection or similar legislation. • In the event of any third party
        claim that the Apple-Enabled Software or the end-user's possession and
        use of that Apple-Enabled Software infringes that third party's
        intellectual property rights, as between HAPPYOMEALS and Apple,
        HAPPYOMEALS, not Apple, will be solely responsible for the
        investigation, defense, settlement and discharge of any such
        intellectual property infringement claim. • You represent and warrant
        that (i) you are not located in a country that is subject to a U.S.
        Government embargo, or that has been designat not listed on any U.S.
        Government list of prohibited or restricted parties. • If you have any
        questions, complaints or claims with respect to the Apple-Enabled
        Software, they should be directed to legal@HAPPYOMEALS.com.
      </p>
      <p className="pb-3">
        HAPPYOMEALS and you acknowledge and agree that Apple, and Apple's
        subsidiaries, are third party beneficiaries of these Terms of Service
        with respect to the Apple-Enabled Software, and that, upon your
        acceptance of the terms and conditions of these Terms of Service, Apple
        will have the right (and will be deemed to have accepted the right) to
        enforce these Terms of Service against you with respect to the
        Apple-Enabled Software as a third party beneficiary thereof.
      </p>
      <p className="pb-3">
        Media Release, Licensing and Intellectual Property Rights
      </p>
      <p className="pb-3">Media Release</p>
      <p className="pb-3">
        You understand that HAPPYOMEALS may use photographs, video, voice
        recordings, and/or biographical information of or about you , your food,
        and / or cooking techniques on HAPPYOMEALS's website and other
        advertising and marketing materials. Such photographs, videos, and
        recordings may be provided by you to HAPPYOMEALS or taken/recorded by
        persons working for HAPPYOMEALS. You irrevocably permit, authorize,
        grant, and license HAPPYOMEALS and its affiliates, successors, and
        assigns, and their respective licensees, advertising agencies, and
        promotion agencies, and the employees, officers, directors, and agents
        of each and all of them ("Authorized Persons"), the rights to use your
        name, image, likeness, voice, professional and personal biographical
        information, and other personal characteristics, and all materials
        created by or on behalf of HAPPYOMEALS that incorporate any of the
        foregoing ("Materials"), in any medium or format and on any platform for
        purposes of advertising, public relations, publicity, and promotion of
        HAPPYOMEALS and its affiliates and their businesses, products, and
        services, without further consent from or compensation to me (all of the
        foregoing rights are hereinafter referred to as "Publicity Rights").
        HAPPYOMEALS shall be the exclusive owner of all intellectual property
        rights, including copyright, in the Materials. You hereby irrevocably
        transfer, assign, and otherwise convey to HAPPYOMEALS my entire right,
        title, and interest, if any, in and to the Materials and all copyrights
        and other intellectual property rights in the Materials. You acknowledge
        and agree that you have no right to review or approve Materials before
        they are used by HAPPYOMEALS and that HAPPYOMEALS has no liability to
        you for any editing or alteration of the Materials.
      </p>
      <p className="pb-3">
        To the fullest extent permitted by applicable law, you hereby
        irrevocably waive all legal and equitable rights relating to all
        liabilities, claims, demands, actions, suits, damages, and expenses
        (collectively, "Claims") arising directly or indirectly from the
        Authorized Persons' exercise of their Publicity Rights under this
        Agreement or the production, exhibition, exploitation, advertising,
        promotion, or other use of the Materials. Whether resulting in whole or
        in part from the negligence of HAPPYOMEALS or any other person and you
        hereby covenant not to make or bring any such Claim against any
        Authorized Persons and forever release and discharge the Authorized
        Persons from liability under such Claims.
      </p>
      <p className="pb-3">Licensing and Intellectual Property Rights.</p>
      <p className="pb-3">
        Service Content, Software and Trademarks: You acknowledge and agree that
        the Service may contain content or features ("Service Content") that are
        protected by copyright, patent, trademark, trade secret or other
        proprietary rights and laws. Except as expressly authorized by
        HAPPYOMEALS, you agree not to modify, copy, frame, scrape, rent, lease,
        loan, sell, distribute or create derivative works based on the Service
        or the Service Content, in whole or in part, except that the foregoing
        does not apply to your own User Content (as defined below) that you
        legally upload to the Service. In connection with your use of the
        Service you will not engage in or use any data mining, robots, scraping
        or similar data gathering or extraction methods. If you are blocked by
        HAPPYOMEALS from accessing the Service (including by blocking your IP
        address), you agree not to implement any measures to circumvent such
        blocking (e.g., by masking your IP address or using a proxy IP address).
        Any use of the Service or the Service Content other than as specifically
        authorized herein is strictly prohibited. The technology and software
        underlying the Service or distributed in connection therewith are the
        property of HAPPYOMEALS, our affiliates and our partners (the
        "Software"). You agree not to copy, modify, create a derivative work of,
        reverse engineer, reverse assemble or otherwise attempt to discover any
        source code, sell, assign, sublicense, or otherwise transfer any right
        in the Software. Any rights not expressly granted herein are reserved by
        HAPPYOMEALS.
      </p>
      <p className="pb-3">
        The HAPPYOMEALS name and logos are trademarks and service marks of
        HAPPYOMEALS (collectively the "HAPPYOMEALS Trademarks"). Other
        HAPPYOMEALS, product, and service names and logos used and displayed via
        the Service may be trademarks or service marks of their respective
        owners who may or may not endorse or be affiliated with or connected to
        HAPPYOMEALS. Nothing in this Terms of Service or the Service should be
        construed as granting, by implication, estoppel, or otherwise, any
        license or right to use any of HAPPYOMEALS Trademarks displayed on the
        Service, without our prior written permission in each instance. All
        goodwill generated from the use of HAPPYOMEALS Trademarks will inure to
        our exclusive benefit.
      </p>
      <p className="pb-3">
        Third Party Material: Under no circumstances will HAPPYOMEALS be liable
        in any way for any content or materials of any third parties (including
        users), including, but not limited to, for any errors or omissions in
        any content, or for any loss or damage of any kind incurred as a result
        of the use of any such content. You acknowledge that HAPPYOMEALS does
        not pre-screen content, but that HAPPYOMEALS and its designees will have
        the right (but not the obligation) in their sole discretion to refuse or
        remove any content that is available via the Service. Without limiting
        the foregoing, HAPPYOMEALS and its designees will have the right to
        remove any content that violates these Terms of Service or is deemed by
        HAPPYOMEALS, in its sole discretion, to be otherwise objectionable. You
        agree that you must evaluate, and bear all risks associated with, the
        use of any content, including any reliance on the accuracy,
        completeness, or usefulness of such content.
      </p>
      <p className="pb-3">
        User Content Transmitted Through the Service: With respect to the
        content or other materials you upload through the Service or share with
        other users or recipients (collectively, "User Content"), you represent
        and warrant that you own all right, title and interest in and to such
        User Content, including, without limitation, all copyrights and rights
        of publicity contained therein. By uploading any User Content, you
        hereby grant and will grant HAPPYOMEALS and its affiliated companies a
        nonexclusive, worldwide, royalty free, fully paid up, transferable,
        sublicensable, perpetual, irrevocable license to copy, display, upload,
        perform, distribute, store, modify and otherwise use your User Content
        in connection with the operation of the Service or the promotion,
        advertising or marketing thereof, in any form, medium or technology now
        known or later developed.
      </p>
      <p className="pb-3">
        You acknowledge and agree that any questions, comments, suggestions,
        ideas, feedback or other information about the Service ("Submissions"),
        provided by you to HAPPYOMEALS are non-confidential and non-proprietary,
        and HAPPYOMEALS will be entitled to the unrestricted use and
        dissemination of these Submissions for any purpose, commercial or
        otherwise, without acknowledgment or compensation to you.
      </p>
      <p className="pb-3">
        You acknowledge and agree that HAPPYOMEALS may preserve content and may
        also disclose content if required to do so by law or in the good faith
        belief that such preservation or disclosure is reasonably necessary to:
        (a) comply with legal process, applicable laws or government requests;
        (b) enforce these Terms of Service; (c) respond to claims that any
        content violates the rights of third parties; or (d) protect the rights,
        property, or personal safety of HAPPYOMEALS, its users and the public.
        You understand that the technical processing and transmission of the
        Service, including your content, may involve (a) transmissions over
        various networks; and (b) changes to conform and adapt to technical
        requirements of connecting networks or devices.
      </p>
      <p className="pb-3">
        Copyright Complaints: HAPPYOMEALS respects the intellectual property of
        others, and we ask our users to do the same. If you believe that your
        work has been copied in a way that constitutes copyright infringement,
        or that your intellectual property rights have been otherwise violated,
        you should notify HAPPYOMEALS of your infringement claim in accordance
        with the procedure set forth below.
      </p>
      <p className="pb-3">
        HAPPYOMEALS will process and investigate notices of alleged infringement
        and will take appropriate actions under the Digital Millennium Copyright
        Act ("DMCA") and other applicable intellectual property laws with
        respect to any alleged or actual infringement. A notification of claimed
        copyright infringement should be emailed to HAPPYOMEALS's Copyright
        Agent at legal@HAPPYOMEALS.com (Subject line: "DMCA Takedown Request").
        You may also contact us by mail at:
      </p>
      <p className="pb-3">No 30 , Margodanahalli Main road, Bangalore 560036</p>
      <p className="pb-3">
        To be effective, the notification must be in writing and contain the
        following information:
      </p>
      <p className="pb-3">
        • an electronic or physical signature of the person authorized to act on
        behalf of the owner of the copyright or other intellectual property
        interest; • a description of the copyrighted work or other intellectual
        property that you claim has been infringed; • a description of where the
        material that you claim is infringing is located on the Service, with
        enough detail that we may find it on the Service; • your address,
        telephone number, and email address; • a statement by you that you have
        a good faith belief that the disputed use is not authorized by the
        copyright or intellectual property owner, its agent, or the law; • a
        statement by you, made under penalty of perjury, that the above
        information in your Notice is accurate and that you are the copyright or
        intellectual property owner or authorized to act on the copyright or
        intellectual property owner's behalf.
      </p>
      <p className="pb-3">
        Counter-Notice: If you believe that your User Content that was removed
        (or to which access was disabled) is not infringing, or that you have
        the authorization from the copyright owner, the copyright owner's agent,
        or pursuant to the law, to upload and use the content in your User
        Content, you may send a written counter-notice containing the following
        information to the Copyright Agent: • your physical or electronic
        signature; • identification of the content that has been removed or to
        which access has been disabled and the location at which the content
        appeared before it was removed or disabled; • a statement that you have
        a good faith belief that the content was removed or disabled as a result
        of mistake or a misidentification of the content; and • your name,
        address, telephone number, and email address, a statement that you
        consent to the jurisdiction of the federal court located within the
        Northern District of California and a statement that you will accept
        service of process from the person who provided notification of the
        alleged infringement. If a counter-notice is received by the Copyright
        Agent, HAPPYOMEALS will send a copy of the counter-notice to the
        original complaining party informing that person that it may replace the
        removed content or cease disabling it in 10 business days. Unless the
        copyright owner files an action seeking a court order against the
        content provider, member or user, the removed content may be replaced,
        or access to it restored, in 10 to 14 business days or more after
        receipt of the counter-notice, at our sole discretion.
      </p>
      <p className="pb-3">
        Repeat Infringer Policy: In accordance with the DMCA and other
        applicable law, HAPPYOMEALS has adopted a policy of terminating, in
        appropriate circumstances and at HAPPYOMEALS's sole discretion, users
        who are deemed to be repeat infringers. HAPPYOMEALS may also at its sole
        discretion limit access to the Service and/or terminate the memberships
        of any users who infringe any intellectual property rights of others,
        whether or not there is any repeat infringement.
      </p>
      <p className="pb-3">Third Party Websites</p>
      <p className="pb-3">
        The Service may provide, or third parties may provide, links or other
        access to other sites and resources on the Internet. HAPPYOMEALS has no
        control over such sites and resources and HAPPYOMEALS is not responsible
        for and does not endorse such sites and resources. You further
        acknowledge and agree that HAPPYOMEALS will not be responsible or
        liable, directly or indirectly, for any damage or loss caused or alleged
        to be caused by or in connection with use of or reliance on any content,
        events, goods or services available on or through any such site or
        resource. Any dealings you have with third parties found while using the
        Service are between you and the third party, and you agree that
        HAPPYOMEALS is not liable for any loss or claim that you may have
        against any such third party.
      </p>
      <p className="pb-3">
        Social Networking; Other Third Parties: From time to time, we may allow
        you to register for the Service using third party services and otherwise
        enable various third party services (e.g., delivery if permitted) to be
        directly integrated into your HAPPYOMEALS experience. By directly
        integrating these services into the Service, we make your online
        experiences richer, easier and more personalized. To take advantage of
        these features, we may ask you to register for or log into such services
        on the websites of their respective providers. By enabling third party
        services within the Service, you are allowing us to pass your log-in
        information to these service providers for this purpose. For more
        information about the implications of activating these third party
        services and HAPPYOMEALS's use, storage and disclosure of information
        related to you and your use of such services within the Service
        (including your friend lists and the like), please see our Privacy
        Policy at https://HAPPYOMEALS.com/privacy. However, please remember that
        the manner in which third party services use, store and disclose your
        information is governed solely by the policies of such third parties,
        and HAPPYOMEALS shall have no liability or responsibility for the
        privacy practices or other actions of any third party site or service
        that may be enabled within the Service.
      </p>
      <p className="pb-3">
        In addition, HAPPYOMEALS is not responsible for the accuracy,
        availability or reliability of any information, content, goods, data,
        opinions, advice or statements made available in connection with third
        party services. As such, HAPPYOMEALS is not liable for any damage or
        loss caused or alleged to be caused by or in connection with use of or
        reliance on any such third party service. HAPPYOMEALS enables these
        features merely as a convenience and the inclusion of such features does
        not imply an endorsement or recommendation.
      </p>
      <p className="pb-3">Indemnification and Release</p>
      <p className="pb-3">
        You agree to release, indemnify and hold HAPPYOMEALS and its affiliates
        and their officers, employees, directors and agents (collectively,
        "Indemnitees") harmless from any and all losses, damages, expenses,
        including reasonable attorneys' fees, rights, claims, actions of any
        kind and injury (including death) arising out of or relating to your use
        of the Service, any User Content, your connection to the Service, your
        breach of these Terms of Service including but not limited to the
        representation and warranties contained herein or your violation of any
        rights of another. Notwithstanding the foregoing, you will have no
        obligation to indemnify or hold harmless any Indemnitee from or against
        any liability, losses, damages or expenses incurred as a result of any
        action of such Indemnitee. If you are a California resident, you waive
        California Civil Code Section 1542, which says: "A general release does
        not extend to claims which the creditor does not know or suspect to
        exist in his favor at the time of executing the release, which if known
        by him must have materially affected his settlement with the debtor." If
        you are a resident of another jurisdiction, you waive any comparable
        statute or doctrine.
      </p>
      <p className="pb-3">Disclaimer of Warranties</p>
      <p className="pb-3">
        YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON
        AN "AS IS" AND "AS AVAILABLE" BASIS. HAPPYOMEALS EXPRESSLY DISCLAIMS ALL
        WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY,
        INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
      </p>
      <p className="pb-3">
        HAPPYOMEALS MAKES NO WARRANTY THAT (I) THE SERVICE WILL MEET YOUR
        REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
        ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
        SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF ANY
        PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED
        BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.
      </p>
      <p className="pb-3">Limitation of Liability</p>
      <p className="pb-3">
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT HAPPYOMEALS WILL NOT BE LIABLE
        FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES,
        OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR
        LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF
        HAPPYOMEALS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
        WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR
        OTHERWISE, RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE
        SERVICE; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
        RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR
        OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR
        FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
        TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON
        THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE SERVICE. IN NO
        EVENT WILL HAPPYOMEALS'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES
        OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID HAPPYOMEALS IN THE
        LAST SIX (6) MONTHS, OR, IF GREATER, ONE HUNDRED DOLLARS ($100).
      </p>
      <p className="pb-3">
        SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN
        WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
        CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET
        FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU.
        IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH THESE
        TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE
        OF THE SERVICE.
      </p>
      <p className="pb-3">
        IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED
        "DISCLAIMER OF WARRANTIES" AND "LIMITATION OF LIABILITY" ARE INTENDED TO
        BE ONLY AS BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW
        JERSEY. IF ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE
        LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION SHALL
        NOT AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE
        SECTIONS.
      </p>
      <p className="pb-3">
        Dispute Resolution By Binding Arbitration: PLEASE READ THIS SECTION
        CAREFULLY AS IT AFFECTS YOUR RIGHTS.
      </p>
      <p className="pb-3">a. Agreement to Arbitrate</p>
      <p className="pb-3">
        This Dispute Resolution by Binding Arbitration section is referred to in
        this Terms of Service as the "Arbitration Agreement." You agree that any
        and all disputes or claims that have arisen or may arise between you and
        HAPPYOMEALS, whether arising out of or relating to this Terms of Service
        (including any alleged breach thereof), the Services, any advertising,
        any aspect of the relationship or transactions between us, shall be
        resolved exclusively through final and binding arbitration, rather than
        a court, in accordance with the terms of this Arbitration Agreement,
        except that you may assert individual claims in small claims court, if
        your claims qualify. Further, this Arbitration Agreement does not
        preclude you from bringing issues to the attention of federal, state, or
        local agencies, and such agencies, if the law allows, seek relief
        against us on your behalf. You agree that, by entering into this Terms
        of Service, you and HAPPYOMEALS are each waiving the right to a trial by
        jury or to participate in a class action. Your rights will be determined
        by a neutral arbitrator, not a judge or jury. The Federal Arbitration
        Act governs the interpretation and enforcement of this Arbitration
        Agreement.
      </p>
      <p className="pb-3">
        You may opt out of the requirement to arbitrate if you have not
        previously agreed to an arbitration provision in HAPPYOMEALS’s Terms of
        Service where you had the opportunity to opt out of the requirement to
        arbitrate. If you have previously agreed to such an arbitration
        provision, you may opt out of any revisions to your prior arbitration
        agreement made by this version of the arbitration agreement in the
        manner specified below, but opting out of this arbitration agreement has
        no effect on any previous, other, or future arbitration agreements that
        you may have with HAPPYOMEALS. Except as specified in this arbitration
        agreement, you may opt out of the Arbitration Agreement by notifying
        HAPPYOMEALS in writing within 30 days of your agreement to these Terms
        of Service. To opt out, you must send a signed written notification to
        9450 SW Gemini Dr, Suite 92716, Beaverton, Oregon 97008-7105 Attention:
        Legal, that includes (a) your account username, (b) your name, (c) your
        address, (d) your telephone number, (e) your email address associated
        with your account, and (f) a clear statement indicating that you wish to
        opt out of the Arbitration Agreement. b. Prohibition of Class and
        Representative Actions and Non-Individualized Relief
      </p>
      <p className="pb-3">
        YOU AND HAPPYOMEALS AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE
        OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER
        IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS
        BOTH YOU AND HAPPYOMEALS AGREE OTHERWISE, THE ARBITRATOR MAY NOT
        CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR PARTY'S CLAIMS AND MAY NOT
        OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR
        CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING
        MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE
        INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO
        PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL CLAIM(S), EXCEPT
        THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR MAY AWARD PUBLIC
        INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO THE EXTENT REQUIRED FOR THE
        ENFORCEABILITY OF THIS PROVISION.
      </p>
      <p className="pb-3">c. Representative PAGA Waiver.</p>
      <p className="pb-3">
        Notwithstanding any other provision of this Agreement or the Arbitration
        Agreement, to the fullest extent permitted by law: (1) you and
        HAPPYOMEALS agree not to bring a representative action on behalf of
        others under the Private Attorneys General Act of 2004 (“PAGA”),
        California Labor Code § 2698 et seq., in any court or in arbitration,
        and (2) for any claim brought on a private attorney general basis,
        including under the California PAGA, both you and HAPPYOMEALS agree that
        any such dispute shall be resolved in arbitration on an individual basis
        only (i.e., to resolve whether you have personally been aggrieved or
        subject to any violations of law), and that such an action may not be
        used to resolve the claims or rights of other individuals in a single or
        collective proceeding (i.e., to resolve whether other individuals have
        been aggrieved or subject to any violations of law) (collectively,
        “representative PAGA Waiver”). Notwithstanding any other provision of
        this Agreement, the Arbitration Agreement or the AAA Rules, disputes
        regarding the scope, applicability, enforceability, revocability or
        validity of this representative PAGA Waiver may be resolved only by a
        civil court of competent jurisdiction and not by an arbitrator. If any
        provision of this representative PAGA Waiver is found to be
        unenforceable or unlawful for any reason: (i) the unenforceable
        provision shall be severed from this Agreement; (ii) severance of the
        unenforceable provision shall have no impact whatsoever on the
        Arbitration Agreement or the requirement that any remaining Claims be
        arbitrated on an individual basis pursuant to the Arbitration Agreement;
        and (iii) any such representative PAGA or other representative private
        attorneys general act claims must be litigated in a civil court of
        competent jurisdiction and not in arbitration. To the extent that there
        are any Claims to be litigated in a civil court of competent
        jurisdiction because a civil court of competent jurisdiction determines
        that the representative PAGA Waiver is unenforceable with respect to
        those Claims, the Parties agree that litigation of those Claims shall be
        stayed pending the outcome of any individual Claims in arbitration.
      </p>
      <p className="pb-3">d. Pre-Arbitration Dispute Resolution</p>
      <p className="pb-3">
        HAPPYOMEALS is always interested in resolving disputes amicably and
        efficiently, and most customer concerns can be resolved quickly and to
        the customer's satisfaction by emailing customer support at
        support@HAPPYOMEALS.com. If such efforts prove unsuccessful, a party who
        intends to seek arbitration must first send to the other, by certified
        mail, a written Notice of Dispute ("Notice"). The Notice to HAPPYOMEALS
        should be sent to 9450 SW Gemini Dr, Suite 92716, Beaverton, Oregon
        97008-7105. The Notice must (i) describe the nature and basis of the
        claim or dispute and (ii) set forth the specific relief sought. If
        HAPPYOMEALS and you do not resolve the claim within sixty (60) calendar
        days after the Notice is received, you or HAPPYOMEALS may commence an
        arbitration proceeding. During the arbitration, the amount of any
        settlement offer made by HAPPYOMEALS or you shall not be disclosed to
        the arbitrator until after the arbitrator determines the amount, if any,
        to which you or HAPPYOMEALS is entitled.
      </p>
      <p className="pb-3">
        e. Arbitration Procedures Arbitration will be conducted by a neutral
        arbitrator in accordance with the American Arbitration Association's
        ("AAA") rules and procedures, including the AAA's Consumer Arbitration
        Rules (collectively, the "AAA Rules"), as modified by this Arbitration
        Agreement. For information on the AAA, please visit its website,
        http://www.adr.org. Information about the AAA Rules and fees for
        consumer disputes can be found at the AAA's consumer arbitration page,
        http://www.adr.org/consumer_arbitration. If there is any inconsistency
        between any term of the AAA Rules and any term of this Arbitration
        Agreement, the applicable terms of this Arbitration Agreement will
        control unless the arbitrator determines that the application of the
        inconsistent Arbitration Agreement terms would not result in a
        fundamentally fair arbitration. The arbitrator must also follow the
        provisions of these Terms of Service as a court would. All issues are
        for the arbitrator to decide, including, but not limited to, issues
        relating to the scope, enforceability, and arbitrability of this
        Arbitration Agreement. Although arbitration proceedings are usually
        simpler and more streamlined than trials and other judicial proceedings,
        the arbitrator awards the same damages and relief on an individual basis
        that a court awards to an individual under the Terms of Service and
        applicable law. Decisions by the arbitrator are enforceable in court and
        may be overturned by a court only for very limited reasons.
      </p>
      <p className="pb-3">
        Unless HAPPYOMEALS and you agree otherwise, any arbitration hearings
        will take place in a reasonably convenient location for both parties
        with due consideration of their ability to travel and other pertinent
        circumstances. If the parties are unable to agree on a location, the
        determination shall be made by AAA. If your claim is for $10,000 or
        less, HAPPYOMEALS agrees that you may choose whether the arbitration
        will be conducted solely on the basis of documents submitted to the
        arbitrator, through a telephonic hearing, or by an in-person hearing as
        established by the AAA Rules. If your claim exceeds $10,000, the right
        to a hearing will be determined by the AAA Rules. Regardless of the
        manner in which the arbitration is conducted, the arbitrator shall issue
        a reasoned written decision sufficient to explain the essential findings
        and conclusions on which the award is based.
      </p>
      <p className="pb-3">f. Costs of Arbitration</p>
      <p className="pb-3">
        Payment of all filing, administration, and arbitrator fees
        (collectively, the "Arbitration Fees") will be governed by the AAA
        Rules, unless otherwise provided in this Arbitration Agreement. If the
        value of the relief sought is $75,000 or less, at your request,
        HAPPYOMEALS will pay all Arbitration Fees. If the value of relief sought
        is more than $75,000 and you are able to demonstrate to the arbitrator
        that you are economically unable to pay your portion of the Arbitration
        Fees or if the arbitrator otherwise determines for any reason that you
        should not be required to pay your portion of the Arbitration Fees,
        HAPPYOMEALS will pay your portion of such fees. In addition, if you
        demonstrate to the arbitrator that the costs of arbitration will be
        prohibitive as compared to the costs of litigation, HAPPYOMEALS will pay
        as much of the Arbitration Fees as the arbitrator deems necessary to
        prevent the arbitration from being cost-prohibitive. Any payment of
        attorneys' fees will be governed by the AAA Rules.
      </p>
      <p className="pb-3">g. Confidentiality</p>
      <p className="pb-3">
        All aspects of the arbitration proceeding, and any ruling, decision, or
        award by the arbitrator, will be strictly confidential for the benefit
        of all parties.
      </p>
      <p className="pb-3">h. Severability</p>
      <p className="pb-3">
        If a court or the arbitrator decides that any term or provision of this
        Arbitration Agreement (other than the subsection (b) titled "Prohibition
        of Class and Representative Actions and Non-Individualized Relief"
        above) is invalid or unenforceable, the parties agree to replace such
        term or provision with a term or provision that is valid and enforceable
        and that comes closest to expressing the intention of the invalid or
        unenforceable term or provision, and this Arbitration Agreement shall be
        enforceable as so modified. If a court or the arbitrator decides that
        any of the provisions of subsection (b) above titled "Prohibition of
        Class and Representative Actions and Non-Individualized Relief" are
        invalid or unenforceable, then the entirety of this Arbitration
        Agreement shall be null and void, unless such provisions are deemed to
        be invalid or unenforceable solely with respect to claims for public
        injunctive relief. The remainder of the Terms of Service will continue
        to apply.
      </p>
      <p className="pb-3">i. Future Changes to Arbitration Agreement</p>
      <p className="pb-3">
        Notwithstanding any provision in this Terms of Service to the contrary,
        HAPPYOMEALS agrees that if it makes any future change to this
        Arbitration Agreement (other than a change to the Notice Address) while
        you are a user of the Services, you may reject any such change by
        sending HAPPYOMEALS written notice within thirty (30) calendar days of
        the change to the Notice Address provided above. By rejecting any future
        change, you are agreeing that you will arbitrate any dispute between us
        in accordance with the language of this Arbitration Agreement as of the
        date you first accepted these Terms of Service (or accepted any
        subsequent changes to these Terms of Service).
      </p>
      <p className="pb-3">Termination</p>
      <p className="pb-3">
        You agree that HAPPYOMEALS, may suspend or terminate your account (or
        any part thereof) or use of the Service and remove and discard any
        content within the Service, for breach of the Terms of Service, for lack
        of use or if HAPPYOMEALS believes that you have violated or acted
        inconsistently with the letter or spirit of these Terms of Service. Any
        suspected fraudulent, abusive or illegal activity that may be grounds
        for termination of your use of Service, may be referred to appropriate
        law enforcement authorities. HAPPYOMEALS may also in its sole discretion
        and at any time discontinue providing the Service, or any part thereof,
        with or without notice. You agree that any termination of your access to
        the Service under any provision of this Terms of Service may be affected
        without prior notice, and acknowledge and agree that HAPPYOMEALS may
        immediately deactivate or delete your account and all related
        information and files in your account and/or bar any further access to
        such files or the Service. Further, you agree that HAPPYOMEALS will not
        be liable to you or any third party for any termination of your access
        to the Service. You may deactivate your account by going to your profile
        on the HAPPYOMEALS.com website, clicking “delete your account” and
        following the instructions or emailing privacy@HAPPYOMEALS.com.
      </p>
      <p className="pb-3">User Disputes</p>
      <p className="pb-3">
        You agree that you are solely responsible for your interactions with any
        other user in connection with the Service and HAPPYOMEALS will have no
        liability or responsibility with respect thereto. HAPPYOMEALS reserves
        the right, but has no obligation, to become involved in any way with
        disputes between you and any other user of the Service.
      </p>
      <p className="pb-3">Confidential Information</p>
      <p className="pb-3">
        You acknowledge that Confidential Information (as defined below) is a
        valuable, special and unique asset of HAPPYOMEALS and agree that you
        will not, for the lifetime of your account on HAPPYOMEALS plus 10 years
        thereafter, disclose, transfer, or use (or seek to induce others to
        disclose, transfer or use) any Confidential Information for any purpose
        other than use of the HAPPYOMEALS Marketplace in accordance with these
        Terms of Service. You shall use best efforts to protect Confidential
        Information from unauthorized disclosure, transfer or use. You shall
        promptly notify HAPPYOMEALS in writing of any circumstances that may
        constitute unauthorized disclosure, transfer, or use of Confidential
        Information. You shall return all originals and any copies of any and
        all materials containing Confidential Information to HAPPYOMEALS
        promptly upon deactivation of your User account or termination of this
        Agreement for any reason whatsoever.
      </p>
      <p className="pb-3">
        The term “Confidential Information” shall mean any and all of
        HAPPYOMEALS’s trade secrets, confidential and proprietary information,
        and all other information and data of HAPPYOMEALS that is not generally
        known to the public or other third parties who could derive value,
        economic or otherwise, from its use or disclosure. Confidential
        Information shall be deemed to include Seller information, Customer
        information, photographs on the HAPPYOMEALS website. technical data,
        know-how, research, product plans, products, services, customers,
        markets, software, developments, inventions, processes, formulas,
        technology, designs, drawings, engineering, hardware configuration
        information, marketing, finances, strategic and other Proprietary
        Materials and confidential information relating to HAPPYOMEALS or
        HAPPYOMEALS’s business, operations or properties, including information
        about HAPPYOMEALS’s staff, Users or partners, or other business
        information disclosed or obtained directly or indirectly in writing,
        orally or by drawings or observation.
      </p>
      <p className="pb-3">General</p>
      <p className="pb-3">
        These Terms of Service constitute the entire agreement between you and
        HAPPYOMEALS and govern your use of the Service, superseding any prior
        agreements between you and HAPPYOMEALS with respect to the Service. You
        also may be subject to additional terms and conditions that may apply
        when you use affiliate or third party services, third party content or
        third party software. These Terms of Service will be governed by the
        laws of the State of California without regard to its conflict of law
        provisions. With respect to any disputes or claims not subject to
        arbitration, as set forth above, you and HAPPYOMEALS agree to submit to
        the personal and exclusive jurisdiction of the state and federal courts
        located within San Francisco County, California. The failure of
        HAPPYOMEALS to exercise or enforce any right or provision of these Terms
        of Service will not constitute a waiver of such right or provision. If
        any provision of these Terms of Service is found by a court of competent
        jurisdiction to be invalid, the parties nevertheless agree that the
        court should endeavor to give effect to the parties' intentions as
        reflected in the provision, and the other provisions of these Terms of
        Service remain in full force and effect. You agree that regardless of
        any statute or law to the contrary, any claim or cause of action arising
        out of or related to use of the Service or these Terms of Service must
        be filed within one (1) year after such claim or cause of action arose
        or be forever barred. A printed version of this agreement and of any
        notice given in electronic form will be admissible in judicial or
        administrative proceedings based upon or relating to this agreement to
        the same extent and subject to the same conditions as other business
        documents and records originally generated and maintained in printed
        form. You may not assign this Terms of Service without the prior written
        consent of HAPPYOMEALS, but HAPPYOMEALS may assign or transfer this
        Terms of Service, in whole or in part, without restriction. The section
        titles in these Terms of Service are for convenience only and have no
        legal or contractual effect. Notices to you may be made via eith changes
        to these Terms of Service or other matters by displaying notices or
        links to notices generally on the Service.
      </p>
      <p className="pb-3">Your Privacy</p>
      <p className="pb-3">
        At HAPPYOMEALS, we respect the privacy of our users. For details, please
        see our Privacy Policy located at https://HAPPYOMEALS.com/privacy. By
        using the Service, you consent to our collection and use of personal
        data as outlined therein.
      </p>
      <p className="pb-3">Questions? Concerns? Suggestions?</p>
      <p className="pb-3">
        Please contact us at legal@HAPPYOMEALS.com to report any violations of
        these Terms of Service or to pose any questions regarding this Terms of
        Service or the Service.
      </p>
      <p className="pb-3">Date of Last Revision: JAN 15, 2024</p>
    </div>
  );
}

export default TermsNConditions;
