import { createSlice } from "@reduxjs/toolkit";

const token = JSON.parse(localStorage.getItem('happyOmeals'))
console.log(token);
const INITIAL_STATE = {
    tokens: token !== null ? token : {},
};

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers:{
    setToken : (state, action) => {
      state.tokens = action.payload
    },
    logout : (state) =>{
      state.tokens = {}
    }
  }
});

export const {setToken, logout} = authSlice.actions

export default authSlice.reducer