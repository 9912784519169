import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsNConditions from "./pages/TermsNConditions";
import Refundpolicy from "./pages/Refundpolicy";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));

function App() {
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />

          {/* Place new routes over this */}
          <Route path="/admin" component={Layout} />
          <Route path="/privacy-policy" component={PrivacyPolicy}/>
          <Route path="/terms-of-service" component={TermsNConditions}/>
          <Route path="/refundpolicy" component={Refundpolicy}/>
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
// rgb(236, 32, 68) preferred red
// rgb(253, 233, 236) light red
