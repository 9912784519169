import React from "react";

export default function Refundpolicy() {
  return (
    <div className="px-3 py-4">
      <h1 className="text-2xl pb-3 text-red-500 text-center">
        CUSTOMER CANCELLATION
      </h1>
      <p className="pb-3">
        As a general rule Buyer shall not be entitled to cancel Order once
        placed. Buyer may choose to cancel Order only within 90 seconds of the
        Order being placed. However, subject to Buyer’s previous cancellation
        history, HOMEALS INDIA PVT LTD reserves the right to deny any refund to
        Buyer pursuant to a cancellation initiated by Buyer even if the same is
        within 90 seconds followed by suspension of account, as may be necessary
        in the sole discretion of HOMEALS INDIA PVT LTD.
      </p>

      <h1 className="text-2xl pb-3 text-red-500 text-center">
        NON-CUSTOMER CANCELLATION
      </h1>
      <p className="pb-3">
        HOMEALS INDIA PVT LTD reserves the right to collect a penalty for the
        Orders constrained to be cancelled by HOMEALS INDIA PVT LTD for reasons
        not attributable to HOMEALS INDIA PVT LTD, including but not limited to:
        in the event if the address provided by Buyer is either wrong or falls
        outside the delivery zone; failure to contact Buyer by phone or email at
        the time of delivering the Order booking; failure to deliver Buyer Order
        due to lack of information, direction or authorization from Buyer at the
        time of delivery; or unavailability of all the items ordered by Buyer at
        the time of booking the Order; or unavailability of all the items
        ordered by Buyer at the time of booking the Order. However, in the
        unlikely event of an item in an Order being unavailable, HOMEALS INDIA
        PVT LTD will contact the Buyer on the phone number provided to us at the
        time of placing the Order and inform Buyer of such unavailability. In
        such an event Buyer will be entitled to cancel the entire Order and
        shall be entitled to a refund to an amount upto 100% of the Order value.
      </p>
      <p className="pb-3">
        In case of cancellations for the reasons attributable to HOMEALS INDIA
        PVT LTD or the Cook or PDP, HOMEALS INDIA PVT LTD shall not collect any
        penalty from the Buyer.
      </p>

      <h1 className="text-2xl pb-3 text-red-500 text-center">REFUNDS:</h1>
      <p className="pb-3">
        Buyer may be entitled to a refund for prepaid Orders. HOMEALS INDIA PVT
        LTD retains the right to retain the penalty payable by the Buyer in
        Section I(2) from the amount refundable to him/her. The Buyer shall also
        be entitled to a refund of proportionate value in the event packaging of
        an item in an Order or the complete Order, is either tampered or damaged
        and the Buyer refuses to accept at the time of delivery for the said
        reason;
      </p>
      <p className="pb-3">
        Buyer may be entitled to a refund upto 100% of the Order value if PDP
        fails to deliver the Order due to a cause attributable to either PDP or
        HOMEALS INDIA PVT LTD, however such refunds will be assessed on a case
        to case basis by HOMEALS INDIA PVT LTD.
      </p>
      <p className="pb-3">
        Our decision on refunds shall be final and binding.
      </p>
      <p className="pb-3">
        All refund amounts shall be credited to Buyer’s source account as may be
        stipulated as per the payment mechanism of Buyer’s choice , the
        estimated timelines are 5-7 Business days , in case Buyer don't choose
        to credit it to Buyer’s wallet with his/her HOMEALS INDIA PVT LTD
        Account;
      </p>
      <p className="pb-3">
        In case of payment at the time of delivery, Buyer will not be required
        to pay for: Orders where packaging is either tampered or damaged at the
        time of delivery; Wrong Order being delivered; or Items missing from
        Buyer’s Order at the time of delivery. Provided the same is communicated
        to HOMEALS INDIA PVT LTD Customer Care through the Platform before the
        Order is marked delivered.
      </p>
      <p className="pb-3">
        In the event if the address provided by the Buyer is either wrong or
        falls outside the HOMEALS INDIA PVT LTD’s delivery zone; failure to
        contact the Buyer by phone or email at the time of delivering the Order;
        failure to deliver the Buyer’s Order due to lack of information,
        direction or authorization from the Buyer at the time of delivery; or
        the Buyer’s failure to provide a valid OTP to the PDP for receiving
        delivery
      </p>

      <p className="pb-3">
        <span className="font-semibold">Refunds: </span>
        The Buyer may be entitled to a refund for prepaid Orders, post deduction
        of cancellation fee, if any for reasons mentioned above or in a manner
        as deemed fit by HOMEALS INDIA PVT LTD in its sole discretion, if refund
        has been requested due to the following reasons:
      </p>

      <p>If the Order could not be delivered within 2 hours;</p>
      <p className="pb-3">
        If the Cook cancels the Order due to reasons not attributable to the
        Buyer, including but not limited to store being closed, non-availability
        of items, store not servicing online Orders, overcrowding at store,
        etc.;
      </p>
      <p className="pb-3">
        If HOMEALS INDIA PVT LTD cancels the Order due to reasons not
        attributable to the Buyer, including but not limited to non-availability
        of PDPs, etc.
      </p>
      <p className="pb-3">
        The Buyer may be entitled to refund due to the aforesaid reasons up to
        100% of the Order value depending upon the nature of issue. HOMEALS
        INDIA PVT LTD reserves the right to consider the cancellation and refund
        request and determine if such cancellation and refund request satisfy
        any of the aforesaid conditions, in which case HOMEALS INDIA PVT LTD
        shall process the cancellation request and refund to Buyer.
      </p>
      <p className="pb-3">
        <span className="font-semibold">Important Note 1:</span>
        The Buyer shall verify his/her Order and the products before providing
        OTP to the PDP and any issue with respect to the product or Buyer’s
        Order shall be notified immediately, and cancellation request shall be
        placed before providing OTP to the PDP. Once OTP is provided to the PDP,
        it shall be deemed that the Buyer has accepted delivery of his/her Order
        and once he/she has accepted delivery, the Buyer cannot cancel the Order
        and/or claim refund.
      </p>
      <p className="pb-3">
        <span className="font-semibold">Important Note 2:</span>
        In case of the complaint of any spurious product, the liability shall
        solely lie with the Cook selling the product. HOMEALS INDIA PVT LTD is
        merely facilitating the transactions between the Cook and the Buyer and
        therefore, HOMEALS INDIA PVT LTD shall assume no liability with respect
        to the products sold by the Cook. HOMEALS INDIA PVT LTD strictly
        discourages dealing of any spurious product on its Platform and shall
        reserve the right to report such incidents to the concerned authorities
        for appropriate legal actions.
      </p>
      
    </div>
  );
}
